import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TaskIcon from '@mui/icons-material/Task';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import Alert from '@mui/material/Alert';
import ApplicationStore from '../../../utils/localStorageUtil';
import AddIcon from '@mui/icons-material/Add';
import FormControl from '@mui/material/FormControl';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import PrintIcon from '@mui/icons-material/Print';
import ReactDOMServer from "react-dom/server";
import Autocomplete from '@mui/material/Autocomplete';
import { Grid } from '@mui/material';
import axios from '../../../api/axios';
import { useAuthContext } from '../../../context/AuthContext';
import PDFPrint from './PDFPrint';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const URL = "./dealership/getClosed";
const ADDURL = "./dealership/addDealershipWarranty";

function ViewClosedWarranty() {
    const user = ApplicationStore().getStorage('user_email');
    const dealership = ApplicationStore().getStorage('dealership');
    const userType = ApplicationStore().getStorage('user_type');
    const userName = ApplicationStore().getStorage('userName');
    const { sidebarItemIndex, setSidebarItemIndex } = useAuthContext();
    const [vinSearch, setVinSearch] = useState(''); // State for VIN search input
    const [lastName, setLastName] = useState(''); // State for VIN search input
    const [dealershipValue, setDealershipValue] = useState(''); // State for VIN search input
    const [dealershipText, setDealershipText] = useState(''); // State for VIN search input
    const [trigger, setTrigger] = useState(false); // State to trigger useEffect

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const [alertOpen, setAlertopen] = useState(false);
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const [dealershipArray, setDealershipArray] = useState([]);
    const handleDealership = (newvalue) => {
        if (newvalue) {
            console.log("value", newvalue.id);
            setDealershipValue(newvalue.id);
            setDealershipText(newvalue.accountName);
        } else {
            setDealershipValue();
        }


    };

    const loadDealership = async () => {
        try {
            const URL = "./dealership";
            const response = await axios.get(URL);

            if (response.data.status === 401) {
                setDealershipArray(""); // Keep dummy data in case of unauthorized response
            } else {
                const responseData = response.data.data;
                // const dataWithIndex = response.data.data.map((item, index) => ({
                //     ...item,
                //     slNo: index + 1, // Assign sequential SL No starting from 1
                // })) || "";
                setDealershipArray(responseData);
            }
        } catch (err) {
            console.log("Error fetching data:", err);
            // Use dummy data if request fails
            setDealershipArray('');
        }
    };


    const navigate = useNavigate();

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertopen(false);
    };

    const columns = [
        { field: 'slNo', headerName: 'SLNO', width: 100 },
        { field: "customerFirstName", headerName: "Customer First Name", width: 250 },
        { field: "customerLastNameText", headerName: "Customer Last Name", width: 250 },
        { field: "vinNo", headerName: "Vin No", width: 250 },
        { field: "make", headerName: "Selected Vehicle", width: 250 },
        { field: "warrantyClassText", headerName: "Package Type", width: 250 },
        // { field: "createdDate", headerName: "Created Date", width: 200 },
        // { field: "CurrentDate", headerName: "Closed Date", width: 200 },
        { field: "user", headerName: "Salesperson", width: 250 },
        { field: "id", headerName: "Internal ID", width: 150 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 150,
            cellClassName: 'actions',
            getActions: (params) => {
                return  userType == "admin" ? [
                    // <EditData selectedRow={params.row}/>,
                    // <DeleteData selectedRow={params.row} />,                        
                    // <Block selectedRow={params.row} />
                    <EditData selectedRow={params.row} />,
                    <PrintPDF selectedRow={params.row} />,
                    <PrintData selectedRow={params.row} />,
                    <DeleteData selectedRow={params.row} />
                ]:[
                    // <EditData selectedRow={params.row}/>,
                    // <DeleteData selectedRow={params.row} />,                        
                    // <Block selectedRow={params.row} />
                    <EditData selectedRow={params.row} />,
                    <PrintPDF selectedRow={params.row} />,
                    <PrintData selectedRow={params.row} />
                   
                ] ;
            }
        },
    ];

    const PDFPrintNew = ({ data }) => {
        return (
            <div style={{ width: "100%" }}>
                <table
                    style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        tableLayout: "fixed", // Ensure fixed table layout for equal width
                    }}
                >
                    <tr>
                        <td rowSpan="6" style={{ border: "1px solid black", width: "50%" }}>
                            First Column Spanning Rows
                        </td>
                        <td style={{ border: "1px solid black", width: "50%" }}>
                            Application ID: 2409136013
                        </td>
                    </tr>
                    <tr>
                        <td style={{ border: "1px solid black" }}>Application ID: 2409136013</td>
                    </tr>
                    <tr>
                        <td style={{ border: "1px solid black" }}>Application ID: 2409136013</td>
                    </tr>
                    <tr>
                        <td style={{ border: "1px solid black" }}>Application ID: 2409136013</td>
                    </tr>
                    <tr>
                        <td style={{ border: "1px solid black" }}>Application ID: 2409136013</td>
                    </tr>
                    <tr>
                        <td style={{ border: "1px solid black" }}>Application ID: 2409136013</td>
                    </tr>
                </table>
            </div>
        )
    }
    const generatePdf = async (data, applicationId) => {
        console.log("data" + JSON.stringify(data));
        const LOGO = await convertImageToBase64();

        // Render the PDFPrint component as static HTML
        // const componentHtml = ReactDOMServer.renderToStaticMarkup(<PDFPrintNew />);

        // Convert the HTML content to pdfMake format
        // const pdfContent = htmlToPdfmake(componentHtml, { window });

        // Create the PDF document definition using the converted content

        const text = data.productName;

        let dealershipaddress = ""

        // Extract the values using regex

        console.log("222222220" + data.dealership);
        var tradeName = "";
        var billingStreet = "";
        var billingCity = "";
        var billingCountry = "";
        var billingZippostalCode = "";
        var ovmic_no = "";
        var accountPhone = "";
        var acc_ovmic_no = "";

        try {
            const response = await axios.get("dealership/" + data.dealership + "/fetch");

            if (response.data.success === 500) {
                console.error("Server error: Failed to fetch dealership data.");
                // Handle the error appropriately, e.g., show an alert or return early
                return;
            }

            const responseData = response.data.data;
            if (responseData) {
                const dealershipaddress = JSON.stringify(responseData[0]); // Converts object to string
                const dealershipData = JSON.parse(dealershipaddress); // Parse it back to an object

                // tradeName = dealershipData.tradeName;
                // tradeName = dealershipData.tradeName;
                // tradeName = dealershipData.tradeName;
                // billingZippostalCode = dealershipData.billingZippostalCode;
                if (dealershipData?.tradeName) tradeName = dealershipData.tradeName;
                if (dealershipData?.billingStreet) billingStreet = dealershipData.billingStreet;
                if (dealershipData?.billingCity) billingCity = dealershipData.billingCity;
                if (dealershipData?.billingCountry) billingCountry = dealershipData.billingCountry;
                if (dealershipData?.billingZippostalCode) billingZippostalCode = dealershipData.billingZippostalCode;
                if (dealershipData?.ovmic_no) ovmic_no = dealershipData.ovmic_no;
                if (dealershipData?.accountPhone) accountPhone = dealershipData.accountPhone;
                if (dealershipData?.acc_ovmic_no) acc_ovmic_no = dealershipData.acc_ovmic_no;

                console.log(tradeName, billingStreet, billingCity, billingCountry, billingZippostalCode, ovmic_no, accountPhone);
            }
        } catch (error) {
            console.error("An error occurred while fetching dealership data:", error);
        }

        let warrantySold = parseFloat(data.salePriceofVehicle.replace(/,/g, '')).toFixed(2);
        // let warrantySold = data.salePriceofVehicle;

        let kilometers = "";
        let customerNM = data.customerFirstNameText + " " + data.customerLastNameText;
        let user = data.user.split("@");
        const monthsMatch = text.match(/\((\d+)\s*months/i);
        let Months = monthsMatch ? parseInt(monthsMatch[1], 10) + " Months" : "-";

        const inserviceDate = new Date(data.serviceDateText);
        const formattedDatefmt = inserviceDate.toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: '2-digit' });

        const vehicleDeliveryDate = new Date(data.vehicleDeliveryDate);
        const vehicleDeliveryDatefmt = vehicleDeliveryDate.toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: '2-digit' });

        // Extract Kilometers
        if (text.includes("/")) {
            let kmMatch = text.split("/");
            if (kmMatch.length > 1) {
                kilometers = kmMatch[1].split(")")[0].trim();
            }
        }

        let applicationtitle = "";

        if (data.warrantyClass == 6) {
            applicationtitle = "Application for GAP Insurance";
        } else {
            applicationtitle = "Application for Warranty Coverage";
        }


        const docDefinition = {
            pageSize: 'A4',
            pageMargins: [20, 20, 20, 20],
            content: [
                // First Table
                {
                    table: {
                        widths: ['50%', '50%'], // Set the width for each column
                        body: [
                            [
                                {
                                    image: LOGO, // Use the 'image' key for the logo
                                    rowSpan: 6,
                                    fit: [150, 150], // Adjust size as needed
                                    border: [false, false, false, false]
                                },
                                { text: `Application ID: ${data.id}`, border: [false, false, false, false], fontSize: 10, alignment: 'right', bold: true }
                            ],
                            ['', { text: `Warranty Status: ${data.Status}`, border: [false, false, false, false], fontSize: 10, alignment: 'right', bold: true }],
                            ['', { text: `Warranty Price: $${parseFloat(data.warrantySoldFor).toFixed(2)}`, border: [false, false, false, false], fontSize: 10, alignment: 'right', bold: true }],
                            ['', { text: ``, border: [false, false, false, false] }],
                            ['', { text: ``, border: [false, false, false, false] }],
                            [
                                { text: 'Application for GAP Bundle Warranty Coverage', rowSpan: 3, border: [false, false, false, false], fontSize: 10, alignment: 'right', bold: true },
                                { text: `25 Sheppard Avenue West, Suite 300, North York, Ontario, M2N 6S6, Phone: 905.291.2940`, border: [false, false, false, false], fontSize: 10, alignment: 'right', bold: true }
                            ],

                            [{ text: 'Get Covered Canada', border: [false, false, false, false], fontSize: 10, bold: true }, { text: 'claims@getcoveredcanada.com', border: [false, false, false, false], fontSize: 10, alignment: 'right', bold: true }],
                            [{ text: `${applicationtitle}`, border: [false, false, false, false], fontSize: 10, bold: true }, { text: 'www.getcoveredcanada.com', border: [false, false, false, false], fontSize: 10, alignment: 'right', bold: true }]
                        ]
                    }
                },
                { text: '\n' }, // Add spacing between the tables
                {
                    table: {
                        widths: ['100%'], // Adjust the column widths for this table
                        body: [
                            [
                                {
                                    text: 'Customer Details', border: [false, false, false, false], bold: true,
                                    alignment: 'left', fontSize: 10
                                },
                            ],

                        ]
                    }
                },
                // { text: '\n' }, // Add spacing between the tables
                {
                    table: {
                        widths: ['100%'], // Adjust the column widths for this table
                        body: [
                            [
                                {
                                    text: '', border: [false, false, false, true], bold: true,
                                    alignment: 'left', fontSize: 10
                                },
                            ],

                        ]
                    }
                },
                {
                    table: {
                        widths: ['20%', '30%', '20%', '30%'], // Adjust the column widths for this table
                        body: [
                            // [
                            //     { text: 'Customer details', border: [false, false, false, false],fontSize: 10, bold: true },
                            //     { text: '', border: [false, false, false, false] },
                            //     { text: '', border: [false, false, false, false] },
                            //     { text: '', border: [false, false, false, false] },
                            // ],
                            [
                                { text: `Customer First Name:`, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: `${data.customerFirstNameText}`, border: [false, false, false, false], fontSize: 8, bold: false },
                                { text: `Address: `, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: `${data.streetAddressText}\n ${data.townText},  ${data.provinceText},  ${data.postalCodeText}`, border: [false, false, false, false], fontSize: 8, bold: false, alignment: "left" }
                            ],
                            [
                                { text: `Customer Last Name:`, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: `${data.customerLastNameText}`, border: [false, false, false, false], fontSize: 8, bold: false },
                                { text: ``, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: ``, border: [false, false, false, false], fontSize: 8, bold: true }
                            ],
                            [
                                { text: `Drivers Licence:`, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: `${data.driverLicenceText}`, border: [false, false, false, false], fontSize: 8, bold: false },
                                { text: `Email:`, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: `${data.customerEmailText}`, border: [false, false, false, false], fontSize: 8, bold: false }
                            ],
                            [
                                { text: `Phone:`, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: `${data.customerPhoneText}`, border: [false, false, false, false], fontSize: 8, bold: false },
                                { text: ``, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: ``, border: [false, false, false, false], fontSize: 8, bold: true }
                            ],


                        ]
                    }
                },
                { text: '\n' }, // Add spacing between the tables  townText. provinceText. postalCodeText
                {
                    table: {
                        widths: ['100%'], // Adjust the column widths for this table
                        body: [
                            [
                                {
                                    text: 'Vehicle Details', border: [false, false, false, false], bold: true,
                                    alignment: 'left', fontSize: 10
                                },
                            ],

                        ]
                    }
                },
                // { text: '\n' }, // Add spacing between the tables
                {
                    table: {
                        widths: ['100%'], // Adjust the column widths for this table
                        body: [
                            [
                                {
                                    text: '', border: [false, false, false, true], bold: true,
                                    alignment: 'left', fontSize: 10
                                },
                            ],

                        ]
                    }
                },

                // Second Table
                {
                    table: {
                        widths: ['20%', '30%', '20%', '30%'], // Adjust the column widths for this table
                        body: [
                            [
                                { text: `Year:`, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: `${data.year}`, border: [false, false, false, false], fontSize: 8, bold: false },
                                { text: `Sale Price:`, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: `$ ${warrantySold}`, border: [false, false, false, false], fontSize: 8, bold: false }
                            ],
                            [
                                { text: `Make:`, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: `${data.make}`, border: [false, false, false, false], fontSize: 8, bold: false },
                                { text: ``, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: ``, border: [false, false, false, false], fontSize: 8, bold: true }
                            ],

                            [
                                { text: `Model:`, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: `${data.model}`, border: [false, false, false, false], fontSize: 8, bold: false },
                                { text: `Vehicle Delivery Date:`, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: `${vehicleDeliveryDatefmt == "Invalid Date" ? "" : vehicleDeliveryDatefmt}`, border: [false, false, false, false], fontSize: 8, bold: false }
                            ],

                            [
                                { text: `VIN:`, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: `${data.vinNoText}`, border: [false, false, false, false], fontSize: 8, bold: false },
                                { text: `In Service Date:`, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: `${formattedDatefmt == "Invalid Date" ? "" : formattedDatefmt}`, border: [false, false, false, false], fontSize: 8, bold: false }
                            ],

                            [
                                { text: `Odometer:`, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: `${data.odometerText} KM`, border: [false, false, false, false], fontSize: 8, bold: false },
                                { text: `Finance Company:`, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: `${data.financeCompanyText}`, border: [false, false, false, false], fontSize: 8, bold: false }
                            ],

                        ]
                    }
                },

                { text: '\n' }, // Add spacing between the tables
                {
                    table: {
                        widths: ['100%'], // Adjust the column widths for this table
                        body: [
                            [
                                {
                                    text: 'Warranty Details', border: [false, false, false, false], bold: true,
                                    alignment: 'left', fontSize: 10
                                },
                            ],

                        ]
                    }
                },
                // { text: '\n' }, // Add spacing between the tables
                {
                    table: {
                        widths: ['100%'], // Adjust the column widths for this table
                        body: [
                            [
                                {
                                    text: '', border: [false, false, false, true], bold: true,
                                    alignment: 'left', fontSize: 10
                                },
                            ],

                        ]
                    }
                },


                // Second Table
                {
                    table: {
                        widths: ['20%', '30%', '20%', '30%'], // Adjust the column widths for this table
                        body: [
                            [
                                { text: `Warranty Plan:`, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: `${data.productName}`, border: [false, false, false, false], fontSize: 8, bold: false },
                                { text: `Deductible:`, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: `${data.deductible}`, border: [false, false, false, false], fontSize: 8, bold: false }
                            ],
                            [
                                { text: `Max Protection:`, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: `${data.warrantyProtectionText}`, border: [false, false, false, false], fontSize: 8, bold: false },
                                { text: `Roadside:`, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: `None`, border: [false, false, false, false], fontSize: 8, bold: false }
                            ],

                            [
                                { text: `Warranty Length:`, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: `${Months}`, border: [false, false, false, false], fontSize: 8, bold: false },
                                { text: ``, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: ``, border: [false, false, false, false], fontSize: 8, bold: true }
                            ],

                            [
                                { text: `Coverage:`, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: `${kilometers}`, border: [false, false, false, false], fontSize: 8, bold: false },
                                { text: ``, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: ``, border: [false, false, false, false], fontSize: 8, bold: true }
                            ],





                        ]
                    }
                },

                { text: '\n' }, // Add spacing between the tables

                {
                    text: 'Terms', // Heading
                    style: { fontSize: 10, bold: true, margin: [0, 10, 0, 10] }, // Style for the heading
                },


                {
                    text: [
                        { fontSize: 8, text: '• I, the Buyer, have read, selected, understand, and accept the terms and conditions of the Warranty Application and the Warranty Policy as outlined in the following pages and have retained a copy of the application and acknowledge that the selling dealer has made no representation outside the Terms and Conditions.\n', margin: [0, 5] },
                        { fontSize: 8, text: '• I, understand and agree that is my obligation to maintain the vehicle according to the manufacturers guidelines, including changing engine oil and filters and monitoring and changing other fluids according to the manufacturers guidelines, and section 3 of this agreement.\n', margin: [0, 5] },
                        { fontSize: 8, text: '• I understand any abuse misuse or neglect of my vehicle may lead to my claims denied. I, understand that I must keep maintenance records and receipts of the maintenance performed as I may be asked to submit these records if a claim is submitted to Get Covered Canada.\n', margin: [0, 5] },
                        { fontSize: 8, text: '• I, understand that maintenance must be performed by licensed mechanic or maintenance shops. Do-it-yourself oil changes are not accepted\n', margin: [0, 5] },
                        { fontSize: 8, text: '• I, understand repairs made to covered components without prior authorization by Get Covered Canada will not be reimbursed. \n', margin: [0, 5] },
                        { fontSize: 8, text: '• I hereby certify that all of the information set out herein is true and accurate, I am applying for coverage at the time of the vehicle purchase or prior to the expiration of an existing full manufacturers warranty and the vehicle is in proper operating condition at the date of the application. Misleading or False Information will void warranty coverage. \n', margin: [0, 5] }
                    ],
                    border: [true, true, true, true]
                },
                { text: '\n' }, // Add spacing between the tables

                {
                    table: {
                        widths: ['25%', '5%', '70%'], // Adjust the column widths for this table
                        body: [
                            [
                                { text: `Applicant's Signature:`, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: ``, border: [false, false, false, false], fontSize: 8, bold: false },
                                { text: `Salesperson's Signature:`, border: [false, false, false, false], fontSize: 8, bold: false },

                            ],
                            [
                                { text: `X`, border: [false, false, false, true], fontSize: 8, bold: true },
                                { text: ``, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: `X`, border: [false, false, false, true], fontSize: 8, bold: false },

                            ],


                        ]
                    }
                },
                {
                    table: {
                        widths: ['30%', '40%', '30%'], // Adjust the column widths for this table
                        body: [
                            [
                                { text: `Customer Name:`, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: `Salesperson:`, border: [false, false, false, false], fontSize: 8, bold: false },
                                { text: `Salesperson #:${data.useromvicno}`, border: [false, false, false, false], fontSize: 8, bold: false },

                            ],
                            [
                                { text: `${customerNM}`, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: `${user[0].toUpperCase()}`, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: ``, border: [false, false, false, false], fontSize: 8, bold: false },
                            ],


                        ]
                    }
                },
                {
                    table: {
                        widths: ['30%', '70%'], // Adjust the column widths for this table
                        body: [
                            [
                                { text: ``, border: [false, false, false, false], fontSize: 8, bold: true },
                                { text: `I, the representative of the selling Dealer, certify that the named used vehicle is mechanically fit`, border: [false, false, false, false], fontSize: 8, bold: true },

                            ],


                        ]
                    }
                },
                {
                    table: {
                        widths: ['30%', '40%', '30%'], // Adjust the column widths for this table
                        body: [
                            [
                                { text: ``, border: [false, false, false, false], fontSize: 8, bold: true },

                                // { text: `${tradeName} \n ${billingStreet} \n ${billingCity} \n ${billingCountry} \n  ${billingZippostalCode}`, border: [false, false, false, false], fontSize: 8, bold: false },
                                { text: `${tradeName}`, border: [false, false, false, false], fontSize: 8, bold: false },
                                // { text: `Reg #: ${ovmic_no} \n Phone: ${accountPhone}`, border: [false, false, false, false], fontSize: 8, bold: false },
                                { text: `Reg #: ${acc_ovmic_no} \n `, border: [false, false, false, false], fontSize: 8, bold: false },
                            ],



                        ]
                    }
                },
                { text: '\n' }, // Add spacing between the tables
                {
                    text: 'Terms and Conditions',
                    style: { fontSize: 8, bold: false, margin: [0, 10, 0, 10] },
                },
                {
                    text: [
                        {
                            fontSize: 8, alignment: 'left', text: `This Warranty Agreement, including the Warranty Holder Registration Page and the Terms and Conditions make up the entire Warranty Agreement (“the Agreement”). No other documents, unless provided directly to you as the warranty holder from Get Covered Canada are legal and binding. Please retain a copy of this Warranty Agreement as evidence of your coverage. Please note that only the parts, labour and benefits listed within this Agreement are covered by this Agreement. This Agreement is an Inclusionary Mechanical Breakdown warranty, and not a service agreement. \n`, margin: [0, 5]
                        },
                        {
                            fontSize: 8, alignment: 'left', text: `1. Essential Warranty Plans:
                                                         Subject to the Terms and Conditions as contained herein, Get Covered Canada (the “Administrator”) warrants that it will repair the Registered Owner’s vehicle (“Warranty Holder”) and all reasonable costs incurred as denoted as covered by this Agreement during the term of the warranty offered under this Agreement, when a Mechanical Failure or Breakdown has occurred. For the purposes of this Agreement, a Breakdown or Mechanical Failure for the purposes of this Agreement shall mean: the failure of a Covered Part under normal service. A Covered Part has failed when it can no longer perform the function for which it was designed solely because of its condition and not because of the action or inaction of any non-Covered Part. Only the parts and labour described below as covered (“Covered Part”) are covered by this Agreement in the event of a Mechanical Failure or a Breakdown. \n`, margin: [0, 5]
                        },
                        {
                            fontSize: 8, alignment: 'left', text: `Coverage under this Agreement can only be applied for in two cases, i) at the time of the sale or lease of the covered vehicle, as applicable to the covered vehicle, being the vehicle identified on the front page of this Agreement (the “Covered Vehicle”), ii) at a point in time prior to the expiration of an existing original Manufacturer’s Warranty. In the case of purchase under the terms of (ii) herein, the coverage purchased under this Agreement must have equal or lesser coverage than the expiring manufacturer warranty. The obligations of Get Covered Canada shall commence immediately or when an existing factory warranty expires, as applicable to the timing of the purchase of this Agreement. The obligations of Get Covered Canada shall expire according to the length of term indicated on the front page of this Agreement, based on the earlier of Agreement term length or odometer reading, whichever occurs first. \n`, margin: [0, 5]
                        },
                        {
                            fontSize: 8, alignment: 'left', text: `on the front page of this Agreement, based on the earlier of Agreement term length or odometer reading, whichever occurs first. Coverage of the Covered Vehicle will begin on the Start Date stated on the first page of this Agreement and upon the issuance of the Warranty Authorization Number, the receipt of this signed Agreement and fulfillment of payment terms as agreed. Get Covered Canada reserves the right to cancel this Agreement in the event of non-payment or if a payment plan is in place, such payments are not up-to-date and current. \n`, margin: [0, 5]
                        },
                        {
                            fontSize: 8, alignment: 'left', text: `The Warranty Holder hereby certifies that the information contained in this Agreement is true and correct as of the Start Date stated on the first page of this Agreement. The Warranty Holder further certifies that the Covered Vehicle is in proper operating condition as of the Start Date stated on the front page of this Agreement. In the event that any information is determined incorrect or misleading, this Agreement shall be deemed void and any monies paid will be returned to the Warranty Holder, less an administration fee of $99.00. \n`, margin: [0, 5]
                        },
                        {
                            fontSize: 8, alignment: 'left', text: `2. Vehicle Eligibility:
                     The following vehicles are not eligible and are specifically excluded from all coverage under this Agreement, unless otherwise approved by Get Covered Canada:
                     • Audi R8 (all other Audi Models are eligible); Ferrari; Aston Martin; Bentley; Bugatti; Lamborghini; Lexus LF4 (all other Lexus Models are eligible); Maserati; Maybach; Mercedes SLR and Mercedes SLS (all other Mercedes Models are eligible); Nissan GTR (all other Nissan models are eligible); Panoz; Rolls-Royce; Fisker Karma; McLaren; and Dodge Viper (all other Dodge Models are eligible);
                     • Any vehicles classified with a payload capacity of more than one (1) ton; or 
                     • Vehicles used partially or exclusively for the following purposes: taxis, courier vehicles (including passenger vehicles used for courier use), chauffeured vehicles, Ride-hailing or Ride-Sharing Services (i.e Uber); delivery vehicles; snowplows or vehicles for which a snowplow is attached; tow-trucks; vehicles used for racing (professional or non-professional); police vehicles; ambulances and mobile canteens trucks;
                     Warranties on vehicles that fall under any of the above excluded categories will be cancelled and any monies received will be returned to the Warranty Holder, through the original selling dealership \n`, margin: [0, 5]
                        },
                        {
                            fontSize: 8, alignment: 'left', text: ` 3. Maintenance Requirements:
                                                      In order to maintain eligibility for coverage, the warranty holder must perform fluid changes on their vehicle according to the manufacturer’s requirements, including any special instructions (i.e.special oil, or increased frequency), for vehicles that have a high portion of short journeys (city driving) or operate in severe weather conditions. Engine oil and filter must be changed every 10,000 kms or once a year (whichever comes first) or according to the manufacture’s guidelines if frequency is more. Do-it-yourself maintenance is not accepted under any circumstance. Required maintenance documentation must include: The name and contact information of the repair or service facility (letterhead format); Date and work order number and/or invoice number; Warranty Holder’s home address and phone number; the vehicles VIN, make, model and year and current kilometres; a description and breakdown of maintenance performed and associated costs; proof of payment by credit card or debit card only (cash not acceptable). The required maintenance documentation may be requested before claim approval. Get Covered Canada reserves the right to refuse claims based on negligence to perform the required maintenance services\n`, margin: [0, 5]
                        },
                        {
                            fontSize: 8, alignment: 'left', text: `4. Components & Benefits of Coverage:
                     According to the warranty plan chosen, as indicated on the first Page of this Agreement (and subject to full payment thereof), the Warranty Holder will receive the coverage indicated within the applicable sections of Section 7 below:
                     i.) Essential Powertrain Plans: Includes components and benefits listed in Section A.
                     ii.) Essential Powertrain Plus Plans: Includes components and benefits listed in Section A and B.
                     iii.) Essential Superior Plans: Includes components and benefits listed in Section A, B, and C.\n`, margin: [0, 5]
                        },
                        {
                            fontSize: 8, alignment: 'left', text: `5. Maximum Liabilities: \n The maximum liability undertaken by Get Covered Canada hereunder shall not exceed,
                     i.) $1000 per claim or half the purchase price of the vehicle whichever is less, and the total liability for the full term of coverage shall not exceed the full purchase price of the vehicle.
                     ii.) $1500 Per Claim or half the purchase price of the vehicle whichever is less, and the total liability for the full term of coverage shall not exceed the full purchase price of the vehicle.
                     iii.) $2500 Per Claim or half the purchase price of the vehicle whichever is less, and the total liability for the full term of coverage shall not exceed the full purchase price of the vehicle.
                     iv.) $3500 Per Claim or half the purchase price of the vehicle whichever is less, and the total liability for the full term of coverage shall not exceed the full purchase price of the vehicle.
                     v.) $4000 Per Claim or half the purchase price of the vehicle whichever is less, and the total liability for the full term of coverage shall not exceed the full purchase price of the vehicle. \n`, margin: [0, 5]
                        },
                        {
                            fontSize: 8, alignment: 'left', text: ` 6. Deductible:
                     According to the front page of this document, all claims are subject to the payment of a deductible of $75, $0, or otherwise indicated on the first page of this contract.\n`, margin: [0, 5]
                        },
                        {
                            fontSize: 8, alignment: 'left', text: ` 7. Warranty Coverage:
                     Only the parts and labour described below (as applicable to your specific coverage selected and identified within Section 4 above) are covered (“covered part”) in the event of a Mechanical Failure or a Breakdown. Diagnostics are covered if the claim is validly covered by this Agreement (Maximum Diagnostic time deemed reasonable is one (1) hour). When reasonable, and at its sole discretion, Get Covered Canada may authorize payment of fluids associated with a repair.\n`, margin: [0, 5]
                        },
                        {
                            fontSize: 8, alignment: 'left', text: ` The following shall be covered parts as outlined or limited herein:
                     (A) provides coverage for:
                     • Engine: Internally lubricated parts when damaged from within including but not limited to: Head gasket, engine block; cylinder heads; crankshaft and main bearings; crankshaft gears; connecting rods and bearings; camshaft and bearings; camshaft gears; push rods; pistons; rings and pins; intake and exhaust valves; valve springs and retainers; guides; lifters; rocker arm; shafts and pivots; harmonic balancer and pulley; timing chain; oil pump and shaft; ring gear, oil separator.
                     • Turbocharger and Supercharger: Internally lubricated parts when damaged from within; including but not limited to: Housing; Wastegate actuator; Compressor valves.
                     • Automatic Transmission: Internally lubricated parts when damaged from within including; but not limited to: torque converter; valve body; gear sets; clutches and bands; oil pump; flywheel; Ring gear.
                     • Manual Transmission/Transaxle: Internally lubricated parts when damaged from within including but not limited to: Housing; Gear sets; Synchronizer rings; Shifter fork; bearings.
                     • Differential (front and rear): Internally lubricated parts when damaged from within including but not limited to: carrier gear and case; drive pinion and pinion gear; differential cover; bearings; mounts.
                     • Transaxle: Internally lubricated parts when damaged from within including but not limited to:Housing; crown and pinion; pinion gears and bearings.
                     • Transfer Case: Internally lubricated parts when damaged from within including but not limited to: housing; gear group; sprocket and chain; bearings; shims.
                     • Trip-Interruption Allowance (Parts & Labour): A maximum of $100.00 per day including applicable sales taxes for a maximum of five (5) days will be reimbursed for lodging expenses at a hotel or motel when a Mechanical Failure or Breakdown is covered by the Agreement. The registered vehicle must be immobilized for a period of more than 24 hours and be more than 250 kilometres away from the Warranty Holder’s address indicated on the first page of this Agreement for such coverage hereunder to be authorized.
                     • Rental Allowance (Parts & Labour): A rental vehicle allowance of $40.00 per day including applicable sales taxes, per day, for a maximum of five (5) days per claim repair occurrence will be granted, upon receipt from a rental establishment or a licensed repair shop. A rental vehicle is only covered if the Breakdown or Mechanical Failure is covered by this Agreement. The Covered Vehicle must be immobilized for more than 24 hours and a valid receipt must be submitted for reimbursement. Please note that there shall be no reimbursement for rental vehicle gas, rental insurance, 407 highway charges or other highway toll charges, accessory charges (such as SirusXM radio or GPS units).
                     (B) provides coverage for:
                     • Air Conditioner (Parts & Labour): Compressor; compressor clutch and pulley; condenser and evaporator; orifice tube; lines; accumulator; dryer; high and low-pressure cutoff switches. Gas filling and other refrigerating products are covered only when a Covered Part is repaired. Cracks or corrosion is excluded from coverage.
                     • Seals & Gaskets (Parts & Labour): Gaskets and oil seals that leak from Covered Parts enumerated in the Agreement. Minor leaks/seepage or fluid oozing are considered normal and are not covered.
                     • Electric System (Parts & Labour): Automatic temperature control/programmer-display unit; Heater blower switches; Main dashboard digital display cluster; Power door locks: switches; Alternator;Starter, Windshield washer pump; Rear window washer pump; Front and rear wipers: motors, switches, regulation and gearing; Power windows: Switches; Power seats: switches; Side-view mirrors: switches; Power sunroof: switches; Headlights: Switches; Headlamp: switches; Turn signals: switches; Cruise control: switch; Rear window defroster: switch; Emergency warning flashers: Switch.
                     (C) provides coverage for:
                     • Brakes (Parts & Labour): Master cylinder; brake booster; wheel cylinders; brake split valve; disc brake calipers; flexible hydraulic brake lines and fittings; oil seals; combination valve; Vacuum assist booster pump; Parking brake linkage and cables.
                     • ABS Brakes/Traction Control System (TCS) (Parts & Labour): ABS/TCS pressure regulator; Pressure accumulator; hydraulic pump; hydraulic pressure valves.
                     • Fuel Injection System (Parts & Labour): Fuel injectors; Fuel pump and metal gas links; fuel tank and fuel gauge; fuel pressure regulator; fuel rails.
                     • Front Suspension (Parts & Labour): Ball joints; upper and lower suspension arms; bushings; hub and wheel bearings (excluding shock absorbers and air suspension).
                     • Power Steering (Parts & Labour): Rack-and-pinion gear; Power Steering Pump; Tie rod ends.\n`, margin: [0, 5]
                        },
                        {
                            fontSize: 8, alignment: 'left', text: `8. How to make a claim:
                     In the event that repairs to the Covered Vehicle become necessary and covered by this Agreement:
                     1) The Warranty Holder must contact Get Covered Canada 905.291.2940 immediately to initiate the claim process;
                     2) The Covered Vehicle may be taken to any licensed mechanic of the Warranty Holder’s choice, including the covered vehicle's franchise store. Get Covered Canada reserves the right to refuse any repair estimate and/or has the right to request a second opinion at an alternative repair facility if a repair estimate is judged unreasonable;
                     3) Get Covered Canada may request the Warranty Holder to produce documentation to substantiate maintenance requirements have been met as per Section 3 of this Agreement.
                     4) In some cases, claims adjusters will be required for onsite assessment(s). The amounts paid by Get Covered Canada for parts and labour are determined by the average similar repair costs or by the MITCHELL GUIDE or similarly recognized repair manuals. The liability of Get Covered Canada under this warranty will extend only to repairs which have been duly authorized by Get Covered
                     Canada and up to the limits identified within Section 5. Pre-Authorization is only granted when the Terms and Conditions set out in this Agreement have been met and this Agreement remains in force and the cost estimate is satisfactory to Get Covered Canada. A Claim Authorization Number is only valid for a period of thirty (30) days and only as applicable to the repair shop identified therein;
                     5) Get Covered Canada may at its sole discretion replace failed parts with OEM, aftermarket, used or rebuilt parts.
                     6) If this Agreement expires, is canceled or terminated by Get Covered Canada (as permitted herein) prior to the work being completed, the repairs will not be covered by this Agreement.
                     7) Get Covered Canada will pay repair costs directly to the mutually agreed upon licensed repair shop after a Claim Authorization Number is given in writing; after repairs are completed; after the final invoice is signed by the Warranty Holder and such is received by Get Covered Canada. The Registered Owner will only have to pay the deductible plus taxes, if applicable, and other miscellaneous non-covered items directly to the licensed mechanic.
                     8) In special circumstances only, Get Covered Canada will reimburse the Warranty Holder for pre-authorized repairs. The Warranty Holder will have to present Get Covered Canada copies of the repair documents including the payment receipt within ten (10) days of paying for the repair to substantiate the reimbursement. Any repair completed outside of the Province of Ontario will be evaluated as per the standards of the Ontario automobile industry. All covered repair costs will be reimbursed in Canadian funds. The Warranty Holder will have to present documents, including the payment receipt to substantiate any rental allowance or trip interruption reimbursements, within ten (10) business days of paying for those services. \n`, margin: [0, 5]
                        },
                        {
                            fontSize: 8, alignment: 'left', text: `9. Warranty Coverage Exclusions:
                     The following are excluded from coverage under this Agreement.
                     a) All parts and labour not included in Part 6 of this document in accordance with the Warranty Plan indicated on the first page of this document;
                     b) Any mechanical defect judged to have existed prior to the Start Date for coverage as listed on the First Page of this Agreement;
                     c) Any repairs not authorized by Get Covered Canada, or carried on at a location not authorized by Get Covered Canada;
                     d) All parts and maintenance services described in the Covered Vehicle maintenance manual, including but not limited to: alignment; adjustments; software updates; wheel balancing; tune-ups; spark plugs; spark plug wires; Supercharger isolator etc.;
                     e) Any electrical wiring repairs; hardware (nuts, bolts etc.); amplifier; glow plugs; hoses; seat belts; wiper blades; shop supplies; storage costs; hoist fees or cleaning materials; heated seats; rear backup cameras; weather stripping; windshield; Lumbar seat bladder; Oil filter housing; diesel AdBlue System; Drive belt pulley and idlers; Valve Cover and/or valve cover gasket; Intake and/or exhaust manifold;
                     f) Mechanical Failure or Breakdown to the Covered Vehicle as a result of inadequate maintenance; abuse; misuse; or neglect or Mechanical Failure or Breakdown caused by a failure to adhere to the manufacturer’s required maintenance schedule;
                     g) Manual transmission clutch, throw out bearing, for solenoid or linkage;
                     h) Any Mechanical Failure or Breakdown due to an accumulation of dirty oil; carbonized or burnt valves; seized piston rings or an accumulation of carbon;
                     i) Any repair or replacement of a Covered Part, if a failure has not occurred (i.e. fraud) or if the wear on the part has not exceeded the field tolerances permitted by the manufacturer;
                     j) Any failure as a result of expired; contaminated; or improper fluids;
                     k) Any failure as a result of not maintaining proper levels of lubricants; fluids; refrigerants; or coolants including damage caused by failure of water hoses; radiators; or their connections; or in the transmission oil cooler lines;
                     l) Any failure caused to Covered Parts by the failure of non-covered parts;
                     m) The Covered Vehicle if it is in any way modified from its factory specifications;
                     n) Four wheel or rear-wheel steering;
                     o) Any repairs where the only malfunction is high oil consumption and/or low compression;
                     p) Any failure caused to a Covered Part(s) by the breakdown of non-manufacturer installed parts;
                     q) Any failure of non-manufacturer installed parts;
                     r) Vehicles used to tow a trailer in excess of the limits recommended by the vehicle’s manufacturer;
                     s) Any failure caused by unauthorized repair or teardown or parts inadequately installed;
                     t) Any parts and repairs already covered by another Canadian or foreign warranty; including but not limited to a manufacturer or repairer’s warranty, or valid insurance policy of any kind;
                     u) All parts and repairs for which the manufacturer has announced its responsibility through any means including recall campaigns and factory service bulletins issued in the United States or Canada;
                     v) Any repairs subject to a class-action lawsuit in the United States or Canada;
                     w) Vehicles with tampered or broken odometers;
                     x) Any Mechanical failure or Breakdown as a result of an accident; collision with another vehicle or road hazard; or falling objects;
                     y) Any Mechanical Failure or Breakdown caused or aggravated by the failure to properly protect the vehicle in the event or indication of failure to an operating part;
                     z) Any failure caused by fire; smoke; explosion; theft; vandalism; protests and riots; acts of terrorism or nuclear contamination;
                     aa) Any Mechanical Failure or Breakdown caused by Acts of God; the weather; environment or a natural disaster; including but not limited to damage caused by water; flood; frost; condensation;lightning; earthquake; fierce winds; hail; rodents or other pests;
                     bb) Any deterioration or any failure caused by rust; corrosion or pitting;
                     cc) Get Covered Canada is also not responsible for any liability for property damage; for injury to or death of any person arising out of the operation; maintenance or use of the vehicle whether or not
                     related to the part(s) covered or following a repair made by a mechanic in an authorized repair shop;
                     dd) Get Covered Canada is not responsible for any time loss; profit loss; inconvenience or any other loss that results from a failure; including delays in parts shipments or approving claims; or
                     ee) Any suspicious noises coming from any parts or components that are in operation and do not affect any other parts or components.\n`, margin: [0, 5]
                        },
                        {
                            fontSize: 8, alignment: 'left', text: `10. Cancellations:
                     This Agreement is non-cancellable except within ten (10) days of the purchase date of the coverage under this Agreement. During this period the Warranty Holder may cancel this Agreement through the selling dealer providing that no claims have been made. A cancellation fee of 25% of the sale price of the warranty (minimum of $100) plus taxes applies in all cases. \n`, margin: [0, 5]
                        },
                        {
                            fontSize: 8, alignment: 'left', text: `11. Total Loss:
                     This Clause only Applies to Finance Companies and if the Covered Vehicle and this Agreement have been financed, the lienholder may cancel this Agreement if your Covered Vehicle is declared a total loss and/or a repossessed for any reason including for non-payment. Get Covered Canada will refund an amount of the warranty Agreement according to the premium paid to Get Covered Canada by the original selling dealer and not the actual financed and/or the amount of the warranty Agreement at the time this warranty Agreement was purchased. Such refund will be according to the following paragraph, less a $75 (seventy-five) Canadian dollars administrative fee, and less any amounts paid out on this Agreement (claims). In the event of cancellation, the lienholder, if any, will be named on a cancellation refund cheque as their interest may appear. Total loss and/or a repossessed in Month 1 = 80% Refund; Total loss and/or a repossessed in Month 2 = 70% Refund; Total loss and/or a repossessed in Month 3 = 60% Refund; Total loss and/or a repossessed in Month 4 = 50% Refund; Total loss and/or a repossessed in Month 5 = 40% Refund; Total loss and/or a repossessed in Month 6 = 30% Refund; Total loss and/or a repossessed in Month 7 = 20% Refund; Total loss and/or a repossessed in Month 8 = 0% Refund, Total loss and/or a repossessed in Month 9 = 0% Refund; Total loss and/or a repossessed in Month 10 = 0% Refund; Total loss and/or a repossessed in Month 11 = 0% Refund; Total loss and/or a repossessed in Month 12 = 0% Refund. \n`, margin: [0, 5]
                        },
                        {
                            fontSize: 8, alignment: 'left', text: ` 12. OMVIC Compliance:
                     OMVIC Compliance: This warranty contract complies with the requirements under the new Motor Vehicle Dealers Act (MVDA). As such, this warranty is not insured but a letter of credit in favour of the Motor Vehicle Dealers Compensation Fund has been provided to the Ontario Motor Vehicle Industry Council (OMVIC) by Get Covered Canada\n`, margin: [0, 5]
                        },
                        {
                            fontSize: 8, alignment: 'left', text: ` 13. Transfer: If all the Terms and Conditions of this Agreement have been met, any remaining benefits may be transferred to a subsequent owner once. This Agreement cannot be transferred to another vehicle. Get Covered Canada must be notified within ten (10) days with the new Warranty Holder’s information (refer to PERSONAL INFORMATION on the front section of this Agreement). A copy of the vehicle registration and the bill of sale must also be emailed or faxed to Get Covered Canada. The new warranty holder is responsible for collecting all maintenance records from the previous owner in the event of a claim. A transfer fee of $100.00 plus taxes will apply, without exceptions and be owing by the named Warranty Holder. A failure to pay the transfer fee will result in a cancellation of the Agreement as of the transfer date without refund, and no claims will be honored thereafter.\n`, margin: [0, 5]
                        },
                        {
                            fontSize: 8, alignment: 'left', text: `14. Financial Agreements: If this Agreement’s coverage was financed either via a payment plan from Get Covered Canada or through a third-party finance company, including an OEM credit arm (the “Funding Party”), the Funding Party shall be entitled to any refunds resulting from the cancellation of this Agreement for any reason, including but not limited to, repossession of the Covered Vehicle,total loss of the Covered Vehicle (including by way of theft). Failure to make monthly payments for this Agreement’s coverage in a timely manner may result in cancellation of this Agreement, under which no refund will be due and no claim will be approved. \n`, margin: [0, 5]
                        },
                        {
                            fontSize: 8, alignment: 'left', text: `15. Right to Recover: If the Warranty Holder has a right to recover funds that Get Covered Canada has paid under this Agreement against another party, the Warranty Holders rights against said third party shall become Get Covered Canada’s rights. The Warranty Holder agrees to provide reasonable assistance to help Get Covered Canada to recover these funds.\n`, margin: [0, 5]
                        },
                        {
                            fontSize: 8, alignment: 'left', text: ` 16. Territory: This Agreement applies only to a Mechanical Failure or Breakdown that occurs and repairs made within Canada and the United States. \n`, margin: [0, 5]
                        },
                        {
                            fontSize: 8, alignment: 'left', text: ` 17. Dispute Resolution: Most disputes or disagreements between Get Covered Canada and the Warranty Holder arising under this Agreement can be resolved quickly by contacting Get Covered Canada at the address noted on the first page of this Agreement. In the event Get Covered Canada is unable to resolve a dispute with the Warranty Holder after attempting to do so informally, the parties to this Agreement agree to resolve such disputes through binding arbitration in accordance with the rules of the Canadian Arbitration Association. The party that intends to seek arbitration must first send to the other party, by certified mail, a written notice of dispute (“NOD”). The NOD should be addressed to other party and contain (a) the nature and basis of the claim or dispute; and (b) set forth the specific relief sought. If the parties do not reach a settlement within thirty (30) days of receipt of the NOD, either party may commence an arbitration proceeding. Unless otherwise agreed to mutually by the parties, the arbitration process will take place in the Province of Ontario.\n`, margin: [0, 5]
                        },
                        {
                            fontSize: 8, alignment: 'left', text: ` 18. Personal Information. The Warranty Holder hereby agrees that any personal information provided to Ensurall during the purchasing of this Agreement, the provision of a payment plan, processing and payment of claims hereunder, and/or the cancellation or transfer of this Agreement, is hereby consented to. Please note that Get Covered Canada may use third party data storage providers outside of Canada and may also need to facilitate claims repairs outside Canada. The Warranty Holder hereby consents to the collection, use, storage and disclosure of their personal information for the purposes outlined herein. \n`, margin: [0, 5]
                        },
                        {
                            fontSize: 8, alignment: 'left', text: `19. This Agreement constitutes the entire agreement between Get Covered Canada and the Warranty Holder and supersedes and extinguishes all previous drafts, agreement, arrangement and understandings between them, whether written or oral, related to this subject matter. \n`, margin: [0, 5]
                        },
                        {
                            fontSize: 8, alignment: 'left', text: `20. This Agreement shall be governed by the laws of the province of Ontario. \n`, margin: [0, 5]
                        },

                    ],
                    border: [true, true, true, true]
                },








            ]
        };

        // Generate the PDF and open it in a new tab
        pdfMake.createPdf(docDefinition).open();
    };


    const generateInvoicePdfNEW2 = async (data) => {
        console.log("data" + JSON.stringify(data));
        const LOGO = await convertImageToBase64();
        // Render the PDFPrint component as static HTML
        // const componentHtml = ReactDOMServer.renderToStaticMarkup(<PDFPrintNew />);

        // Convert the HTML content to pdfMake format
        // const pdfContent = htmlToPdfmake(componentHtml, { window });

        // Create the PDF document definition using the converted content

        var tradeName = "";
        var billingStreet = "";
        var billingCity = "";
        var billingCountry = "";
        var billingZippostalCode = "";
        var ovmic_no = "";
        var accountPhone = "";

        try {
            const response = await axios.get("dealership/" + data.dealership + "/fetch");

            if (response.data.success === 500) {
                console.error("Server error: Failed to fetch dealership data.");
                // Handle the error appropriately, e.g., show an alert or return early
                return;
            }

            const responseData = response.data.data;
            if (responseData) {
                const dealershipaddress = JSON.stringify(responseData[0]); // Converts object to string
                const dealershipData = JSON.parse(dealershipaddress); // Parse it back to an object

                // tradeName = dealershipData.tradeName;
                // tradeName = dealershipData.tradeName;
                // tradeName = dealershipData.tradeName;
                // billingZippostalCode = dealershipData.billingZippostalCode;
                if (dealershipData?.tradeName) tradeName = dealershipData.tradeName;
                if (dealershipData?.billingStreet) billingStreet = dealershipData.billingStreet;
                if (dealershipData?.billingCity) billingCity = dealershipData.billingCity;
                if (dealershipData?.billingCountry) billingCountry = dealershipData.billingCountry;
                if (dealershipData?.billingZippostalCode) billingZippostalCode = dealershipData.billingZippostalCode;
                if (dealershipData?.ovmic_no) ovmic_no = dealershipData.ovmic_no;
                if (dealershipData?.accountPhone) accountPhone = dealershipData.accountPhone;

                console.log(tradeName, billingStreet, billingCity, billingCountry, billingZippostalCode, ovmic_no, accountPhone);
            }
        } catch (error) {
            console.error("An error occurred while fetching dealership data:", error);
        }


        const match = data.productName.match(/(\d+)\s*Months/);
        let months = "";

        if (match) {
            months = parseInt(match[1], 10) + " Months";
            console.log(months); // Output: 60
        } else {
            console.log("Months not found");
        }
        let warrantyAdminCost = data.warrantySoldFor;
        let taxCost = (warrantyAdminCost * (13 / 100));
        let totalCost = parseFloat(Number(warrantyAdminCost) + Number(taxCost), 2);


        let liabilityLimit = parseFloat(0).toFixed(2);
        let unLimitedMileage = parseFloat(0).toFixed(2);
        let deductible = parseFloat(0).toFixed(2);
        let totTrueCost = parseFloat(0).toFixed(2);

        // Convert back to numbers for calculation and update totTrueCost
        totTrueCost = parseFloat(totTrueCost) + parseFloat(liabilityLimit) + parseFloat(unLimitedMileage) + parseFloat(deductible);

        const date = new Date("10-03-2025");
        const formattedDate = date.toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: '2-digit' });
        const docDefinition = {
            pageSize: 'A4',
            pageMargins: [20, 20, 20, 20],
            content: [
                // First Table
                {
                    table: {
                        widths: ['50%', '50%'], // Set the width for each column
                        body: [
                            [
                                {
                                    image: LOGO, // Use the 'image' key for the logo
                                    rowSpan: 5,
                                    fit: [150, 150], // Adjust size as needed
                                    border: [false, false, false, false]
                                },
                                { text: ``, border: [false, false, false, false] }
                            ],
                            ['', { text: `25 Sheppard Avenue West, Suite 300, North York, Ontario,`, fontSize: 10, alignment: 'right', bold: true, border: [false, false, false, false], }],
                            ['', { text: ` M2N 6S6, Phone: 905.291.2940`, fontSize: 10, bold: true, border: [false, false, false, false], alignment: 'right' }],
                            ['', { text: `claims@getcoveredcanada.com`, fontSize: 10, bold: true, border: [false, false, false, false], alignment: 'right' }],
                            ['', { text: `www.getcoveredcanada.com`, bold: true, fontSize: 10, border: [false, false, false, false], alignment: 'right' }],
                            [{ text: `Get Covered Canada`, fontSize: 12, bold: true, border: [false, false, false, false] }, { text: ``, bold: true, border: [false, false, false, false] }],
                            [{ text: `SERVING CANADIANS SINCE 1978`, fontSize: 10, bold: true, border: [false, false, false, false] }, { text: ``, bold: true, border: [false, false, false, false] }],



                        ]
                    }
                },
                {
                    table: {
                        widths: ['100%'], // Adjust the column widths for this table
                        body: [
                            [
                                {
                                    text: 'Invoice', border: [false, false, false, true], bold: true,
                                    alignment: 'center', fontSize: 18
                                },
                            ],

                        ]
                    }
                },
                { text: '\n' }, // Add spacing between the tables
                {
                    table: {
                        widths: ['50%', '50%'], // Adjust the column widths for this table
                        body: [
                            [
                                {
                                    text: `Date: ${formattedDate}`, border: [false, false, false, false], bold: true,
                                    alignment: 'left', fontSize: 8
                                },
                                {
                                    text:'', border: [false, false, false, false], bold: true,
                                    alignment: 'left', fontSize: 8
                                },
                            ],

                        ]
                    }
                },
                { text: '\n' }, // Add spacing between the tables
                {
                    table: {
                        widths: ['40%', '60%'], // Adjust the column widths for this table
                        body: [
                            [
                                {
                                    text: `${tradeName} \n ${billingStreet} \n ${billingCity} \n ${billingCountry} \n  ${billingZippostalCode}`, border: [false, false, false, false], bold: true,
                                    alignment: 'left', fontSize: 8
                                },
                                {
                                    text: '', border: [false, false, false, false], bold: true,
                                    alignment: 'left', fontSize: 8
                                },
                            ],

                        ]
                    }
                },

                {
                    table: {
                        widths: ['30%', '50%', '20%'], // Adjust the column widths for this table
                        body: [

                            [
                                { text: ``, border: [false, false, false, false] },
                                { text: '', border: [false, false, false, false] },
                                { text: ``, border: [false, false, false, false] }
                            ],
                            [
                                { text: `Merchant: ${data.merchantno}`, fontSize: 9, bold: true, border: [false, false, false, false] },
                                { text: ``, border: [false, false, false, false] },
                                { text: ``, border: [false, false, false, false] }
                            ],
                            [
                                { text: `Invoice:#${data.invno}`, fontSize: 9, bold: true, border: [false, false, false, false] },
                                { text: ``, fontSize: 10, border: [false, false, false, false] },
                                { text: ``, border: [false, false, false, false] }
                            ],
                            [
                                { text: ``, border: [false, false, false, false] },
                                { text: '', border: [false, false, false, false] },
                                { text: ``, border: [false, false, false, false] }
                            ],
                            [
                                { text: ``, border: [false, false, false, false] },
                                { text: '', border: [false, false, false, false] },
                                { text: ``, border: [false, false, false, false] }
                            ],
                            [
                                { text: `Application ID:`, fontSize: 9, bold: true, border: [false, false, false, false] },
                                { text: `${data.id}`, fontSize: 8, border: [false, false, false, false] },
                                { text: ``, border: [false, false, false, false] }
                            ],
                            [
                                { text: `Customer Full Name:`, fontSize: 9, bold: true, border: [false, false, false, false] },
                                { text: `${data.customerFirstNameText} ${data.customerLastNameText}`, fontSize: 8, border: [false, false, false, false] },
                                { text: ``, border: [false, false, false, false] }
                            ],
                            [
                                { text: `Vehicle Information: `, fontSize: 9, bold: true, border: [false, false, false, false] },
                                { text: `${data.yearText + ' ' + data.modelText}`, fontSize: 7, bold: false, border: [false, false, false, false] },
                                { text: ``, border: [false, false, false, false] }
                            ],
                            [
                                { text: `Warranty Plan:  `, fontSize: 9, bold: true, border: [false, false, false, false] },
                                { text: ` ${data.productName}`, fontSize: 8, border: [false, false, false, false] },
                                { text: ``, border: [false, false, false, false] }
                            ],
                            [
                                { text: `Warranty Length:  `, fontSize: 9, bold: true, border: [false, false, false, false] },
                                { text: `${months}`, fontSize: 8, border: [false, false, false, false] },
                                { text: ``, border: [false, false, false, false] }
                            ],
                            [
                                { text: `Liability Limit:  `, fontSize: 9, bold: true, border: [false, false, false, false] },
                                { text: `${data.warrantyProtectionText} `, fontSize: 8, border: [false, false, false, false] },
                                { text: ``, border: [false, false, false, false] }
                            ],
                            [
                                { text: `Warranty Admin Cost:  `, fontSize: 10, bold: true, border: [false, false, false, true] },
                                { text: ``, border: [false, false, false, true] },
                                { text: `$${warrantyAdminCost}`, fontSize: 8, border: [false, false, false, true], alignment: 'right' }
                            ],
                            [
                                { text: `Total Cost:  `, fontSize: 10, bold: true, border: [false, false, false, false] },
                                { text: ``, border: [false, false, false, false] },
                                { text: `$${warrantyAdminCost}`, fontSize: 8, border: [false, false, false, false], alignment: 'right' }
                            ],
                            [
                                { text: ``, fontSize: 10, bold: true, border: [false, false, false, false] },
                                { text: ``, border: [false, false, false, false] },
                                { text: ``, fontSize: 8, border: [false, false, false, false], alignment: 'right' }
                            ],
                            [
                                { text: `Tax 13%:  `, fontSize: 10, bold: true, border: [false, false, false, true] },
                                { text: ``, border: [false, false, false, true] },
                                { text: `$${taxCost}`, fontSize: 8, border: [false, false, false, true], alignment: 'right' }
                            ],
                            [
                                { text: `Total Cost:  `, fontSize: 10, bold: true, border: [false, false, false, false] },
                                { text: ``, border: [false, false, false, false] },
                                { text: `$${totalCost}`, fontSize: 8, bold: true, border: [false, false, false, false], alignment: 'right' }
                            ],
                            [
                                { text: `HST #: 105511554RP002  `, fontSize: 10, bold: true, border: [false, false, false, false] },
                                { text: ``, border: [false, false, false, false] },
                                { text: ``, border: [false, false, false, false], alignment: 'right' }
                            ],

                        ]
                    }
                },
                { text: '\n' }, // Add spacing between the tables
                { text: '\n' }, // Add spacing between the tables
                { text: '', pageBreak: 'before' },
                {
                    table: {
                        widths: ['30%', '30%', '40%'], // Adjust the column widths for this table
                        body: [

                            [
                                { text: ``, fontSize: 10, bold: true, border: [false, false, false, true] },
                                { text: `True Total Cost`, fontSize: 13, bold: true, border: [false, false, false, true] },
                                { text: ``, border: [false, false, false, true] }
                            ],
                            [
                                { text: `Application ID:`, fontSize: 9, bold: true, border: [false, false, false, false] },
                                { text: `${data.id}`, fontSize: 8, border: [false, false, false, false] },
                                { text: ``, border: [false, false, false, false] }
                            ],
                            [
                                { text: `Customer Full Name:`, fontSize: 9, bold: true, border: [false, false, false, false] },
                                { text: `${data.customerFirstNameText} ${data.customerLastNameText}`, fontSize: 8, border: [false, false, false, false] },
                                { text: ``, border: [false, false, false, false] }
                            ],
                            [
                                { text: `Vehicle Information: `, fontSize: 9, bold: true, border: [false, false, false, false] },
                                { text: `${data.yearText + ' ' + data.modelText}`, fontSize: 8, bold: false, border: [false, false, false, false] },
                                { text: ``, border: [false, false, false, false] }
                            ],
                            [
                                { text: `Warranty Plan:  `, fontSize: 9, bold: true, border: [false, false, false, false] },
                                { text: ` ${data.productName}`, fontSize: 8, border: [false, false, false, false] },
                                { text: ``, border: [false, false, false, false] }
                            ],
                            [
                                { text: `Warranty Length:  `, fontSize: 9, bold: true, border: [false, false, false, false] },
                                { text: `${months}`, fontSize: 8, border: [false, false, false, false] },
                                { text: ``, border: [false, false, false, false] }
                            ],
                            [
                                { text: `Liability Limit:  `, fontSize: 9, bold: true, border: [false, false, false, false] },
                                { text: `${data.warrantyProtectionText} `, fontSize: 8, border: [false, false, false, false] },
                                { text: `$${data.productCost}`, fontSize: 8, border: [false, false, false, false], alignment: 'right' }
                            ],
                            [
                                { text: `Unlimited Milage:  `, fontSize: 9, bold: true, border: [false, false, false, false] },
                                { text: `NO`, fontSize: 7, border: [false, false, false, false] },
                                { text: `$${unLimitedMileage}`, fontSize: 8, border: [false, false, false, false], alignment: 'right' }
                            ],
                            [
                                { text: `Deductible:  `, fontSize: 9, bold: true, border: [false, false, false, true] },
                                { text: `${data.deductibleText} `, fontSize: 8, border: [false, false, false, true] },
                                { text: `$${deductible}`, fontSize: 8, border: [false, false, false, true], alignment: 'right' }
                            ],

                            [
                                { text: `Total True Cost:  `, fontSize: 10, bold: true, border: [false, false, false, false] },
                                { text: ``, border: [false, false, false, false] },
                                { text: `$${data.productCost}`, bold: true, fontSize: 8, border: [false, false, false, false], alignment: 'right' }
                            ],


                        ]
                    }
                },














            ]
        };

        // Generate the PDF and open it in a new tab
        pdfMake.createPdf(docDefinition).open();
    };



    const [dataList, setDataList] = useState(''); // Initialize with dummy data

    useEffect(() => {
        loadData();
        loadDealership();
        if (trigger) {
            console.log("useEffect is triggered by the action!");
            // Perform side effects here (e.g., fetching data, updating the DOM)

            // Reset trigger to avoid continuous execution
            setTrigger(false);
        }
    }, [trigger]);

    const warrantyData = [
        {
            "id": 1,
            "packages": 4,
            "packagesTypes": 0,
            "productIndex": 7,
            "productCost": "519",
            "packagesText": "Gap Financial Protection bundle",
            "productName": "GAP Financial Protection Bundle - Max Liability: $75,000 - Financed Amt: $0 - $75,000 (96 Months/unlimted)",
            "vinNo": "2589",
            "vinNoText": "2589",
            "make": "BMW",
            "makeText": "BMW",
            "model": "X1",
            "modelText": "X1",
            "year": 1998,
            "yearText": 1998,
            "odometer": "25000",
            "odometerText": "25000",
            "salePriceofVehicle": "5222",
            "salePriceofVehicleText": "5222",
            "comprehensiveFactoryWarrantyValid": "Yes",
            "comprehensiveFactoryWarrantyValidText": "Yes",
            "serviceDate": "2024-10-01",
            "serviceDateText": "2024-10-01",
            "warrantyClass": "Essential GCC",
            "warrantyClassText": "Essential GCC",
            "warrantyType": "Interior Shield",
            "warrantyTypeText": "Interior Shield",
            "warrantyProtection": "Extended",
            "warrantyProtectionText": "Extended",
            "warrantyOption": "1",
            "warrantyOptionText": "1",
            "highRatioCoverage": "Yes",
            "highRatioCoverageText": "Yes",
            "deductible": "$0 Deductible",
            "deductibleText": "$0 Deductible",
            "customerFirstName": "Manoj",
            "customerFirstNameText": "Manoj",
            "customerLastName": "Nayak",
            "customerLastNameText": "Nayak",
            "streetAddress": "kodila",
            "streetAddressText": "kodila",
            "town": "mangalore",
            "townText": "mangalore",
            "province": "British Columbia",
            "provinceText": "British Columbia",
            "postalCode": "857485",
            "postalCodeText": "857485",
            "customerPhone": "8574859685",
            "customerPhoneText": "8574859685",
            "customerEmail": "Abhi@gmail.com",
            "customerEmailText": "Abhi@gmail.com",
            "driverLicence": "78596",
            "driverLicenceText": "78596",
            "customerLanguage": "Francais",
            "customerLanguageText": "Francais",
            "dealNotes": "89655",
            "dealNotesText": "89655",
            "vinCust": "",
            "vinCustText": "",
            "salePriceofVehicleCust": "5222",
            "salePriceofVehicleCustText": "5222",
            "financeCompany": "mangalore",
            "financeCompanyText": "mangalore",
            "vehicleDeliveryDate": "2024-10-01",
            "vehicleDeliveryDateText": "2024-10-01",
            "warrantySoldFor": "966666666666666",
            "warrantySoldForText": "966666666666666"
        },
        {
            "id": 2, "packages": 4, "packagesTypes": 0, "productIndex": 3, "productCost": "519", "packagesText": "Gap Financial Protection bundle", "productName": "GAP Financial Protection Bundle - Max Liability: $75,000 - Financed Amt: $0 - $75,000 (96 Months/unlimted)",
            "vinNo": "258888888888", "vinNoText": "258888888888", "make": "Mercedes", "makeText": "Mercedes", "model": "C-Class", "modelText": "C-Class", "year": 2000, "yearText": 2000, "odometer": "25000", "odometerText": "25000", "salePriceofVehicle": "5222", "salePriceofVehicleText": "5222", "comprehensiveFactoryWarrantyValid": "No", "comprehensiveFactoryWarrantyValidText": "No", "serviceDate": "2024-10-01", "serviceDateText": "2024-10-01", "warrantyClass": "Appearnce Packages", "warrantyClassText": "Appearnce Packages", "warrantyType": "Rust Shield", "warrantyTypeText": "Rust Shield", "warrantyProtection": "Extended", "warrantyProtectionText": "Extended", "warrantyOption": "1", "warrantyOptionText": "1", "highRatioCoverage": "Yes", "highRatioCoverageText": "Yes", "deductible": "$0 Deductible", "deductibleText": "$0 Deductible", "customerFirstName": "Arun", "customerFirstNameText": "Arun", "language": "0", "languageText": "None", "customerLastName": "Nayak", "customerLastNameText": "Nayak", "streetAddress": "mysore", "streetAddressText": "mysore", "town": "mangalore", "townText": "mangalore", "province": "British Columbia", "provinceText": "British Columbia", "postalCode": "857485", "postalCodeText": "857485", "customerPhone": "89685748596", "customerPhoneText": "89685748596", "customerEmail": "arun@gmail.com", "customerEmailText": "arun@gmail.com", "driverLicence": "87458596", "driverLicenceText": "87458596", "customerLanguage": "Francais", "customerLanguageText": "Francais", "dealNotes": "87458555", "dealNotesText": "87458555", "vinCust": "258888888888", "vinCustText": "258888888888", "salePriceofVehicleCust": "5222", "salePriceofVehicleCustText": "5222", "financeCompany": "mangalore", "financeCompanyText": "mangalore", "vehicleDeliveryDate": "2024-10-02", "vehicleDeliveryDateText": "2024-10-02", "warrantySoldFor": "966666666666666", "warrantySoldForText": "966666666666666"
        }
    ]

    const loadData = async () => {
        try {
            const response = await axios.post(URL, { dealership });

            if (response.data.status === 401) {
                setDataList(""); // Keep dummy data in case of unauthorized response
            } else {
                console.log(response.data.data)
                const responseData = response.data.data;
                const dataWithIndex = response.data.data.map((item, index) => ({
                    ...item,
                    slNo: index + 1, // Assign sequential SL No starting from 1
                })) || "";
                setDataList(dataWithIndex);
            }
        } catch (err) {
            console.log("Error fetching data:", err);
            // Use dummy data if request fails
            setDataList('');
        }
    };

    const loadDateData = async () => {
        if (fromDate === "" || toDate === "") {
            alert("Both From Date and To Date cannot be empty!");
            return false;
        }

        if (new Date(toDate) <= new Date(fromDate)) {
            alert("To Date must be greater than From Date!");
            return false;
        } else {
            try {
                const response = await axios.post(URL, { dealership, fromDate, toDate });

                if (response.data.status === 401) {
                    setDataList(""); // Keep dummy data in case of unauthorized response
                } else {
                    console.log(response.data.data)
                    const responseData = response.data.data;
                    const dataWithIndex = response.data.data.map((item, index) => ({
                        ...item,
                        slNo: index + 1, // Assign sequential SL No starting from 1
                    })) || "";
                    setDataList(dataWithIndex);
                }
            } catch (err) {
                console.log("Error fetching data:", err);
                // Use dummy data if request fails
                setDataList('');
            }
        }
    }



    const EditData = (props) => {
        return (
            <ContentCopyIcon style={{ cursor: "pointer" }} onClick={(e) => {
                e.stopPropagation();
                // navigate('/BuildWarranty', { state: { type: "update", data: props.selectedRow } });
                // if (props.selectedRow && typeof props.selectedRow === 'object') {
                //     // Clone the object to avoid mutating the original
                //     const updatedRow = { ...props.selectedRow };

                //     // Check if the key 'Status' exists and update its value
                //     if (updatedRow.Status === 'Closed Won') {
                //         updatedRow.Status = 'Pending';
                //     }
                //     SaveAsPending(updatedRow);
                //     // console.log("Updated selected row:", updatedRow);
                // } else {
                //     // console.log("Invalid row data:", props.selectedRow);
                // }
                e.stopPropagation();
                ApplicationStore().setStorage('sideBarIndex', 3);
                setSidebarItemIndex(3);
                navigate('/BuildWarranty', { state: { type: "recreate", data: props.selectedRow } });

            }} />
        );
    }

    const DeleteData = (props) => {
        return (
            <Tooltip title="Delete">
                <DeleteIcon
                    onClick={() => {

                        const isConfirmed = window.confirm("Are you sure you want to delete?");
                        if (isConfirmed) {
                            console.log(props.selectedRow.id);
                            const data = { id: props.selectedRow.id, user:userName };
                            const mainURL = './dealership/' + data.id + '/deletesByIdWarranty';
                            serviceMethod(mainURL, "POST", data, handleSuccess, handleException);
                        }

                    }}
                />
            </Tooltip>

        );
    };

    const serviceMethod = async (mainURL, method, data, handleSuccess, handleException) => {
        console.log("helo");
        try {
            const response = await axios.post(mainURL, data);
            // if (saveStatusRef.current === 1) {
            //     generatePdf(data);
            // } else {
            //     generateInvoicePdf(data);
            // }

            return handleSuccess(response.data);

        } catch (err) {
            if (!err?.response) {
                console.log("No server response");
            } else {
                return handleException(err?.response.data);
            }
        }
    };

    const SaveAsPending = async (data) => {
        const { id, warrantyApplicationDate, user, dealership, vinNo, highRatioCoveragePriceText, warrantyOptionPriceText, vinNoText, make, makeText, model, modelText, year, yearText, odometer, odometerText, salePriceofVehicle, salePriceofVehicleText, comprehensiveFactoryWarrantyValid, comprehensiveFactoryWarrantyValidText, serviceDate, serviceDateText, warrantyClass, warrantyClassText, warrantyType, warrantyTypeText, warrantyProtection, warrantyProtectionText, warrantyOption, warrantyOptionText, highRatioCoverage, highRatioCoverageText, deductible, deductibleText, customerFirstName, customerFirstNameText, language, languageText, customerLastName, customerLastNameText, streetAddress, streetAddressText, town, townText, province, provinceText, postalCode, postalCodeText, customerPhone, customerPhoneText, customerEmail, customerEmailText, driverLicence, driverLicenceText, customerLanguage, customerLanguageText, dealNotes, dealNotesText, vinCust, vinCustText, salePriceofVehicleCust, salePriceofVehicleCustText, financeCompany, financeCompanyText, vehicleDeliveryDate, vehicleDeliveryDateText, warrantySoldFor, warrantySoldForText, packages, packagesTypes, productIndex, productCost, packagesText, productName } = data;
        // generateInvoicePdf(data);

        // if (!termsConditonChecked) {
        //     alert("Please tick the terms & conditions")
        // } else {          
        const mainURL = ADDURL;
        serviceMethod(mainURL, 'POST', data, handleSuccess, handleException);

        // }


    }

    const handleSuccess = (data) => {
        setSeverity("success");
        setMessage(data.data);
        setAlertopen(true);
        setTimeout(() => {
            console.log("test");
            setTrigger(true);  // This will trigger useEffect
            setAlertopen(false);
        }, 3000); // Matches autoHideDuration
        // setTimeout(() => {
        //     console.log("SaveStatus", saveStatusRef)
        //     if (saveStatusRef.current === 1) {
        //         ApplicationStore().setStorage('sideBarIndex', "4");
        //         setSidebarItemIndex(4);
        //         navigate("/ViewPendingWarranty");
        //     } else {
        //         ApplicationStore().setStorage('sideBarIndex', "5");
        //         setSidebarItemIndex(5);
        //         navigate("/ViewClosedWarranty");
        //     }

        // }, 3000); // Matches autoHideDuration
    }

    const handleException = (data) => {
        setSeverity("error");
        setMessage(data.data);
        setAlertopen(true);
        setTimeout(() => {
            console.log("test");
            setTrigger(true);  // This will trigger useEffect
            setAlertopen(false);
        }, 3000);
    }

    const convertImageToBase64 = async () => {
        // Make sure the image path is relative to the public folder
        const response = await fetch(`${process.env.PUBLIC_URL}/carCanadaLogo.png`);
        const blob = await response.blob();

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(blob);
        });
    };

    const PrintPDF = (props) => {
        return (
            <PrintIcon
                style={{ cursor: "pointer" }} onClick={(e) => {
                    // e.stopPropagation();              
                    // navigate('/PDFGen', { state: { type: "update", data: props.selectedRow } });
                    generatePdf(props.selectedRow);
                }} />

        );
    };

    const PrintData = (props) => {
        return (
            <AttachMoneyIcon
                style={{ cursor: "pointer" }} onClick={(e) => {
                    // e.stopPropagation();              
                    // navigate('/PDFGen', { state: { type: "update", data: props.selectedRow } });
                    // generateInvoiceNewPdf(props.selectedRow);
                    generateInvoicePdfNEW2(props.selectedRow);
                }} />

        );
    };




    const handleSubmit = (e) => {
        navigate('/AddDealership', { state: { type: "add", value: "" } });
        // const method = "POST";
        // const data = { oldPassword: currentPassword, newPassword, email: user_email };
        // const mainURL = URL;
        // serviceMethod(mainURL, method, data, handleSuccess, handleException);
    }

    let filteredRows = dataList;

    // Filter by VIN
    if (vinSearch) {
        filteredRows = filteredRows.filter((row) =>
            row.vinNo?.toLowerCase().includes(vinSearch.toLowerCase())
        );
    }

    // Filter by Last Name
    if (lastName) {
        filteredRows = filteredRows.filter((row) =>
            row.customerLastNameText?.toLowerCase().includes(lastName.toLowerCase())
        );
    }

    if (lastName) {
        filteredRows = filteredRows.filter((row) =>
            row.customerLastNameText?.toLowerCase().includes(lastName.toLowerCase())
        );
    }

    if (dealershipValue) {
        filteredRows = filteredRows.filter((row) =>
            row.dealership == dealershipValue // Direct comparison for integers
        );
    }

    return (
        <div style={{ marginTop: "0px", padding: "0px" }}>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar />
                <Box sx={{ flexGrow: 1, padding: '10px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <Typography variant="subtitle2">From Date</Typography>
                                <TextField
                                    // label="From Date"
                                    variant="outlined"
                                    type="date" // This makes the field accept only dates
                                    id="joinDate"
                                    value={fromDate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                    sx={{ marginBottom: 2 }}  // Add some spacing below the input
                                    fullWidth  // Make it full width
                                />
                            </FormControl>

                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <Typography variant="subtitle2">To Date</Typography>
                                <TextField
                                    // label="From Date"
                                    variant="outlined"
                                    type="date" // This makes the field accept only dates
                                    id="joinDate"
                                    value={toDate}
                                    onChange={(e) => setToDate(e.target.value)}
                                    sx={{ marginBottom: 2 }}  // Add some spacing below the input
                                    fullWidth  // Make it full width
                                />
                            </FormControl>

                        </Grid>

                        <Grid item xs={4}>
                            {
                                userType == "admin" ?
                                    <FormControl fullWidth required>
                                        <Typography variant="subtitle2">Dealership</Typography>
                                        <Autocomplete
                                            // value={"dealershipText"}
                                            options={dealershipArray}
                                            onChange={(event, newValue) => handleDealership(newValue)} // Handle selection
                                            getOptionLabel={(option) => option.accountName || ''} // Adjust to match the property you want to display
                                            isOptionEqualToValue={(option, value) => option.id === value.id} // Adjust to match unique keys
                                            sx={{ width: '100%' }} // Responsive width for the TextField
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select Dealership" // Add a label for clarity
                                                />
                                            )}
                                        />
                                    </FormControl> : ""
                            }

                        </Grid>


                        <Grid item xs={2}>
                            <Typography variant="subtitle2">Last Name</Typography>
                            <TextField
                                label="Search by Last Name"
                                variant="outlined"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}  // Update search input
                                sx={{ marginBottom: 2 }}  // Add some spacing below the input
                                fullWidth  // Make it full width
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle2">VIN No</Typography>
                            <TextField
                                label="Search by VIN"
                                variant="outlined"
                                value={vinSearch}
                                onChange={(e) => setVinSearch(e.target.value)}  // Update search input
                                sx={{ marginBottom: 2 }}  // Add some spacing below the input
                                fullWidth  // Make it full width
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Stack spacing={3}>
                    <Stack direction="row" spacing={3}>
                        <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
                            {/* <Typography
                                variant="h6"
                                sx={{
                                    fontWeight: 'medium',
                                    color: 'darknavy',
                                    fontFamily: 'Montserrat, sans-serif',
                                    display: 'flex',        // Align items in a row
                                    alignItems: 'center',   // Vertically center the icon and text
                                }}
                            >
                                <TaskIcon sx={{ fontSize: '20px' }} /> &nbsp;Closed warranty
                            </Typography> */}
                            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                                {/* <Button color="inherit" 
                            onClick={(e) => handleSubmit(e)}
                            sx={{
                                backgroundColor: 'purple',
                                color:'white',  // Change background color to purple
                                '&:hover': {
                                    backgroundColor: 'purple',  // Darken the color on hover
                                },
                            }}
                            
                            startIcon={<AddIcon fontSize="var(--icon-fontSize-md)" />}>
                                Add Dealership
                            </Button> */}
                                {/* <Button color="inherit" startIcon={<CloudDownloadIcon fontSize="var(--icon-fontSize-md)" />}>
                                Export
                            </Button> */}
                            </Stack>
                        </Stack>

                        <div>
                            {/* <Button startIcon={<AddIcon fontSize="var(--icon-fontSize-md)" />} variant="contained" onClick={toggleDrawer("right", true)}>
                                Add
                            </Button> */}
                        </div>
                    </Stack>
                </Stack>
                <br></br>
                {/* <Customer  toggleDrawer={toggleDrawer} state={state}  /> */}
                <DataGrid
                    rows={filteredRows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    experimentalFeatures={{ newEditingApi: false }}
                    components={{
                        Toolbar: () => (
                            <Box
                                sx={{
                                    padding: 2,
                                    display: 'flex',
                                    // justifyContent: 'flex-start', // Aligns to the left
                                    borderBottom: '0.2px solid grey', // Adds bottom border
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontWeight: 'medium',
                                        // fontSize: '16px', // Use fontSize instead of font
                                        color: 'darknavy',
                                        fontFamily: 'Montserrat, sans-serif' // Ensure proper font family syntax
                                    }}
                                >
                                    Closed Warranty
                                </Typography>
                                <Button
                                    color="inherit"
                                    onClick={(e) => loadDateData(e)}
                                    sx={{
                                        backgroundColor: '#0d2365',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: '#0d2365',
                                        },
                                        borderRadius: '10px',
                                        marginLeft: 'auto', // Pushes the button to the right
                                    }}
                                // startIcon={<AddIcon fontSize="var(--icon-fontSize-md)" />}
                                >
                                    Fetch Data
                                </Button>
                                <Button
                                    color="inherit"
                                    onClick={(e) => loadData()}
                                    sx={{
                                        backgroundColor: '#0d2365',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: '#0d2365',
                                        },
                                        borderRadius: '10px',
                                        marginLeft: '10px', // Pushes the button to the right
                                    }}
                                // startIcon={<AddIcon fontSize="var(--icon-fontSize-md)" />}
                                >
                                    Reset Data
                                </Button>
                            </Box>
                        ),
                    }}
                    sx={{
                        // height: 'calc(100vh - 200px)', // Adjust height dynamically based on screen size
                        height: 'auto',
                        '& .MuiDataGrid-columnHeaderTitle': {
                            color: 'darknavy', // Set column header text color to dark navy blue
                            fontWeight: 'bold', // Make column header text bold
                        },
                    }}
                />
            </Box>
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={severity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </Snackbar>

        </div>
    );
}

export default ViewClosedWarranty;
